html,
body {
  margin: 0;
  height: 100%;
  background-color: #333333;
  font-family: "VT323", monospace;
}

.App-header {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: auto;
}

.App-header-two {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow-y: scroll;
}

h1 {
  background-color: #333333;
  border-radius: 1px;
  font-family: "VT323", monospace;
  text-transform: lowercase;
  font-size: calc(80px + 2vmin);
  margin-top: 0%;
  margin-bottom: 2.5vh;
  color: #4dc9ff;
}

h2 {
  border-radius: 1px;
  font-family: "VT323", monospace;
  text-transform: lowercase;
  font-size: calc(20px + 2vmin);
  margin-top: 0%;
  margin-bottom: 2.5vh;
  color: #b8e986;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

h3 {
  border-radius: 1px;
  font-family: "VT323", monospace;
  text-transform: lowercase;
  margin-top: 0%;
  margin-bottom: 2.5vh;
  color: #ffd300;
  text-align: center;
}

#type {
  font-size: calc(20px + 2vmin);
  margin-top: 0vh;
  margin-bottom: 2.5vh;
  color: #b8e986;
}

#contact-type {
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
}

#about-type {
  margin-top: 1em;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
}

#skills-button-color {
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
  color: #b679a2;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  position: relative;
  z-index: 1;
  border-radius: 50%;
}

#skills-button-color:hover {
  cursor: pointer;
  background-color: #333333;
  color: #ff7e79;
}

#button-color {
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
  color: #ffd300;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  position: relative;
  z-index: 1;
  border-radius: 50%;
}

#button-color:hover {
  background-color: #333333;
  color: #ff7e79;
}

.button {
  display: inline-block;
  background-color: transparent;
  margin: 0.8vw;
  margin-bottom: 0;
  height: 100px;
  width: 100px;
}

.button .icon_title {
  display: none;
}

.button:hover .icon_title {
  display: initial;
}

.button:hover .icon {
  display: none;
}

#nav-buttons {
  align-items: center;
  justify-content: center;
  color: #eee;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  position: relative;
  z-index: 1;
}

#nav-buttons:hover {
  background-color: #333333;
  color: #ff7e79;
}

#bio-block {
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  h1 {
    font-size: calc(60px + 2vmin);
  }
  h2 {
    font-size: calc(20px + 2vmin);
  }
  #button-color {
    height: 75px;
    width: 75px;
    font-size: calc(10px + 2vmin);
  }
  .button {
    margin: 0.1vw;
    height: 75px;
    width: 75px;
  }
  .nav-buttons {
    height: 75px;
    width: 75px;
    font-size: calc(10px + 2vmin);
  }
}
